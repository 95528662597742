<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Source of Data:">
        <select-box
          width="240"
          :options="originData"
          :value.sync="extraData.dataSourceCode"
          placeholder="Please select source of data"
        />
      </el-form-item>
      <el-form-item label="Status:">
        <select-box
          width="240"
          :options="statusOptions"
          :value.sync="extraData.status"
        />
      </el-form-item>
      <el-form-item label="source code:">
        <el-input
          v-Int
          type="number"
          style="width:240px"
          clearable
          v-model="extraData.sourceEventCode"
          placeholder="Please typing in source code"
        ></el-input>
      </el-form-item>
      <el-form-item label="source Name:">
        <el-input
          style="width:240px"
          clearable
          v-model="extraData.thirdName"
          placeholder="Please typing in source name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Standard Code:">
        <el-input
          style="width:240px"
          clearable
          v-Int
          type="number"
          v-model="extraData.standardEventCode"
          placeholder="Please typing in Standard code"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="Standard Name:">
        <select-box
          :url="STD_SEARCH_EVENT_CODE"
          :queryOrderParams="{ page: 1, size: 100 }"
          :value.sync="extraData.standardName"
          search-key="eventCodeName"
          label-key="standardEventName"
          value-key="standardEventName"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="addDialogShow">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
    >
      <el-table-column
        label="Source of Data"
        width="140"
        align="center"
        prop="dataSourceCode"
      >
        <template v-slot="{ row }"
          >{{ originMapping[row.dataSourceCode] }}
        </template>
      </el-table-column>
      <el-table-column
        label="source Code"
        width="140"
        align="center"
        prop="sourceEventCode"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="source Name"
        min-width="180"
        align="center"
        prop="thirdEventName"
      ></el-table-column>
      <el-table-column
        label="Standard Code"
        min-width="160"
        align="center"
        prop="standardEventCode"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="Standard Name"
        min-width="160"
        align="center"
        prop="standardEventName"
      ></el-table-column>
      <el-table-column label="Status" align="center" width="120">
        <template v-slot="{ row }">
          <p :class="{ red: !row.status, green: row.status }">
            • {{ row.status ? 'Mapped' : 'UnMapped' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Third_Event'" :dataId="row.thirdEventId" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="150px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Source Name:">
          {{ dialogForm.thirdName }}
        </el-form-item>
        <el-form-item label="Standard Name:" prop="standardId" required>
          <select-box
            :url="STD_SEARCH_EVENT_CODE"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            :queryOrderParams="{ page: 1, size: 100 }"
            search-key="eventCodeName"
            label-key="standardEventName"
            value-key="standardEventCode"
          />
        </el-form-item>
        <el-form-item label="Standard Code:">
          {{ dialogForm.standardId }}
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="dialog.isLoading"
            @click="dialogSubmit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="addDialog.title"
      width="500px"
      :visible.sync="addDialog.show"
      :close-on-click-modal="addDialog.clickModal"
    >
      <el-form
        :model="addForm"
        :rules="addRules"
        label-width="150px"
        size="mini"
        :ref="addDialog.ref"
      >
        <el-form-item label="Source Data:" prop="dataSourceCode">
          <select-box
            width="240"
            :options="originData"
            :value.sync="addForm.dataSourceCode"
            placeholder="Please select source of data"
          />
        </el-form-item>

        <el-form-item label="Source code:" prop="eventCode">
          <el-input
            v-Int
            type="number"
            style="width:240px"
            clearable
            v-model="addForm.eventCode"
            placeholder="Please typing in source code"
          ></el-input>
        </el-form-item>
        <el-form-item label="Source Name:" prop="name">
          <el-input
            style="width:240px"
            clearable
            v-model="addForm.name"
            placeholder="Please typing in source name"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :loading="addDialog.isLoading"
            @click="addDialogComfirm"
            >Confirm</el-button
          >
          <el-button @click="addDialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEventCodeMappingList as getData,
  editEventCodeMapping as editData,
  addEventCodeMapping
} from '@/service/subscribe'
import TableMixin from '@/mixins/subscibe/table'
export default {
  name: 'EventCodeMapping',
  mixins: [TableMixin],
  data() {
    return {
      addDialog: {
        title: 'Event Code Mapping Add',
        show: false,
        ref: 'addDiologForm',
        clickModal: false,
        isLoading: false
      },
      addForm: {
        eventCode: '',
        dataSourceCode: '',
        name: ''
      },
      extraData: {
        standardName: ''
      },
      addRules: {
        dataSourceCode: [
          {
            message: 'Please typing select dataSourceCode',
            required: true
          }
        ],
        eventCode: [
          {
            message: 'Please enter the event code',
            required: true
          }
        ],
        name: [
          {
            message: 'Please enter the name',
            required: true
          }
        ]
      },
      rules: {
        standardId: [
          {
            message: 'Please typing select standard name',
            required: true
          }
        ]
      }
    }
  },
  watch: {
    'addDialog.show': function(val) {
      !val && this.addFormReset()
    }
  },
  methods: {
    addDialogShow() {
      this.addDialog.show = true
    },
    addDialogComfirm() {
      this.$refs[this.addDialog.ref].validate(async valid => {
        if (valid) {
          this.addDialog.isLoading = true
          addEventCodeMapping(this.addForm)
            .then(res => {
              if (res.code === 0) {
                this.getList()
                this.addDialogHide()
                this.$message({
                  message: 'Successful operation',
                  type: 'success'
                })
              }
            })
            .finally(() => {
              this.addDialog.isLoading = false
            })
        }
      })
    },
    addDialogHide() {
      this.addDialog.show = false
    },
    addFormReset() {
      this.addDialog.isLoading = false
      this.addForm = {}
      try {
        this.$refs[this.addDialog.ref] &&
          this.$refs[this.addDialog.ref].resetFields()
      } catch (e) {
        console.log(e)
      }
    },
    getData,
    editData,
    edit(row) {
      const data = {
        thirdId: row.thirdEventId,
        standardId: row.standardEventCode,
        standardName: row.standardEventName,
        thirdName: row.thirdEventName
      }
      this.dialogEdit(data)
    }
  }
}
</script>
